import React, {useState} from 'react';
import PropTypes from 'prop-types';

const CheckBoxes = ({area, callback}) => {
  const [selected] = useState([])
  const onSelect = (areaItem, selector) => {
    if (selected.indexOf(areaItem) === -1) {
      selected.push(areaItem)
    }
    document.getElementsByClassName(selector)[0].classList.toggle('active')
    callback(selected)
  }

  switch (area) {
    case 'timegruppen':
      return (
        <div className="grid checkboxes checkboxes--5">
          <div
            className="area-item area-item--first cursor-pointer pl-8"
            onClick={() => onSelect('Forsikring & Pension', 'area-item--first')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Forsikring & Pension</span>
          </div>
          <div
            className="area-item area-item--middle area-item--middle-1 cursor-pointer pl-8"
            onClick={() => onSelect('Revision', 'area-item--middle-1')}>
            <div className="box">
              <span className="box__check">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Revision</span>
          </div>
          <div
            className="area-item area-item--middle area-item--middle-2 cursor-pointer pl-8"
            onClick={() => onSelect('Investering', 'area-item--middle-2')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Investering</span>
          </div>
          <div
            className="area-item area-item--middle area-item--middle-3 cursor-pointer pl-8"
            onClick={() => onSelect('Jura', 'area-item-')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Jura</span>
          </div>
          <div
            className="area-item area-item--last cursor-pointer pl-8"
            onClick={() => onSelect('GDPR', 'area-item--last')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">GDPR</span>
          </div>
        </div>
      )
    case 'forsikring-og-pension':
      return (
        <div className="grid checkboxes checkboxes--2">
          <div
            className="area-item area-item--first cursor-pointer pl-8"
            onClick={() => onSelect('Pension', 'area-item--first')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Forsikring</span>
          </div>
          <div
            className="area-item area-item--middle cursor-pointer pl-8"
            onClick={() => onSelect('Forsikring', 'area-item--middle')}>
            <div className="box">
              <span className="box__check">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <g>
                    <path strokeWidth="3" stroke="#ffffff" d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z " fill="none"/>
                  </g>
                </svg>
              </span>
            </div>
            <span className="label">Pension</span>
          </div>
        </div>
      )
    default:
      return null;
  }
}

CheckBoxes.propTypes = {
  area: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
}

export default CheckBoxes;

