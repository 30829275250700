import React, {useEffect, useState} from 'react';
import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import {onError} from '@apollo/client/link/error';
import {Query} from '@apollo/client/react/components/Query'
import {InMemoryCache} from 'apollo-cache-inmemory';
import PropTypes from 'prop-types';
import {
  GET_MENUS,
  GET_PAGE,
  GET_SUBSITE_PAGES,
  GET_TIMEINVEST_FUNDS_PAGE,
  GET_TIMEINVEST_ABOUT_PAGE,
  GET_TIMEINVEST_PAGE_SECTIONS,
  GET_NEWS_PAGE, GET_TIMEINVEST_KNOWLEDGE_PAGE, GET_EMPLOYEES, GET_CONTACT_PAGE
} from '../../../gql';
import {
  LoadingIndicator,
  Error,
  InvestmentFunds, AppShell,
} from '../../components';
import DefaultPage from '../default';
import GetStarted from '../GetStarted';
import TimeInvestAbout from './About';
import InvestDefault from './Page';
import LegalDocuments from './LegalDocuments';
import News from '../News';
import KnowledgeCenter from './KnowledgeCenter';
import AboutTeam from '../AboutTeam';
import Contact from '../Contact';
import NewsPost from '../NewsPost';
import NotFound from '../404';

const devMode = process.env.NODE_ENV === 'development';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // do something with graphql error
    console.error({ graphQLErrors });
  }

  if (networkError) {
    // do something with network error
    console.error({ networkError });
  }
});

const httpLink = new HttpLink({
  uri: devMode
    ? 'https://timegruppen-stage.maintenance.twentyfour.dev/timeinvest/graphql'
    : `https://${process.env.HOSTNAME}/timeinvest/graphql`
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

const cache = new InMemoryCache({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const client = new ApolloClient({
  cache,
  link,
  resolvers: {},
});

const InvestQuery = ({children, page}) => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  let query = GET_PAGE
  const queryVars = {stringId: page.id}

  switch (page.uri) {
    case '/om-os/':
      query = GET_TIMEINVEST_ABOUT_PAGE
      break
    case '/om-os/vores-team/':
      query = GET_EMPLOYEES
      break
    case '/vi-tilbyder/udvalg-af-fonde/':
      query = GET_TIMEINVEST_FUNDS_PAGE
      break
    case '/vi-tilbyder/udvildige-investeringsloesninger/':
    case '/vi-tilbyder/formueplan/':
    case '/vi-tilbyder/timefamilyoffice/':
      query = GET_TIMEINVEST_PAGE_SECTIONS
      break
    case '/blog/':
      query = GET_NEWS_PAGE
      queryVars.first = 8
      break
    case '/videnscenter/':
      query = GET_TIMEINVEST_KNOWLEDGE_PAGE
      break
    case '/kontakt/':
      query = GET_CONTACT_PAGE
      break
    default:
      query = GET_PAGE
  }

  useEffect(() => {
    client.query({ query: query, variables: queryVars })
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  if (loading || !result) {
    return <div>
      <div className="full-page-spinner"><LoadingIndicator /></div>
      <div style={{ position: 'relative', height: '100vh', width: '100vw', display: 'block'}}></div>
    </div>
  } else if (error) {
    return <Error />
  }
  return children(result.data)
}

InvestQuery.propTypes = {
  page: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
}

const TimeinvestRouter = ({data}) => {
  const [loading, setLoading] = useState(true) // eslint-disable-line no-unused-vars
  const [result, setResult] = useState(null) // eslint-disable-line no-unused-vars
  const [error, setError] = useState(null) // eslint-disable-line no-unused-vars
  let { path } = useRouteMatch();

  useEffect(() => {
    client.query({query: GET_MENUS})
      .then((result) => {
        setLoading(false)
        setResult(result)
      })
      .catch((error) => {
        setError(error)
      })
  }, [])

  const renderRoutes = (page) => {
    const pathLocation = '/timeinvest'+page.uri;
    switch (pathLocation) {

      case '/timeinvest/':
        return <Route exact key={page.id} path={'/timeinvest/'}>
          <InvestQuery page={page}>{(data) => <><DefaultPage page={data.page} />
          </>}</InvestQuery>
        </Route>
      case '/timeinvest/privatlivspolitik/':
        return <Route key={page.id} path={'/timeinvest/privatlivspolitik/'}>
          <div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} />
        </Route>
      case '/timeinvest/juridiske-dokumenter/':
        return <Route key={page.id} path={'/timeinvest/juridiske-dokumenter/'}>
          <InvestQuery page={page}>{(data) => <LegalDocuments page={data.page}/>}</InvestQuery>
        </Route>
      case '/timeinvest/kom-igang/':
        return <Route key={page.id} path={'/timeinvest/kom-igang/'}>
          <GetStarted area="timeinvest" />
        </Route>
      case '/timeinvest/kontakt/':
        return <Route key={page.id} path={'/timeinvest/kontakt/'}>
          <InvestQuery page={page}>{(data) => <Contact data={data} area={'timeinvest'}/> }</InvestQuery>
        </Route>
      case '/timeinvest/om-os/':
        return <Route key={page.id} path={'/timeinvest/om-os/'}>
          <InvestQuery page={page}>{(data) => <TimeInvestAbout page={data.page}/>}</InvestQuery>
        </Route>
      case '/timeinvest/om-os/vores-team/':
        return <Route key={page.id} path={'/timeinvest/om-os/vores-team/'}>
          <InvestQuery page={page}>{(data) => <AboutTeam invest={true} data={data}/>}</InvestQuery>
        </Route>
      case '/timeinvest/videnscenter/':
        return <Route key={page.id} path={'/timeinvest/videnscenter/'}>
          <InvestQuery page={page}>{(data) => <KnowledgeCenter page={data.page}/>}</InvestQuery>
        </Route>
      case '/timeinvest/blog/':
        return <Route key={page.id} path={'/timeinvest/blog/'}>
          <InvestQuery page={page}>{(data) => <News subsite="TI" page={data}/>}</InvestQuery>
        </Route>
      case '/timeinvest/vi-tilbyder/':
        return <Route key={page.id} path={'/timeinvest/vi-tilbyder/'}>
          <InvestQuery page={page}>{(data) => <DefaultPage page={data.page}/>}</InvestQuery>
        </Route>
      case '/timeinvest/vi-tilbyder/timefamilyoffice/':
        return <Route key={page.id} path={'/timeinvest/vi-tilbyder/timefamilyoffice/'}>
          <InvestQuery page={page}>
            {(data) => (
              <InvestDefault page={data.page} />
            )}
          </InvestQuery>
        </Route>
      case '/timeinvest/vi-tilbyder/udvildige-investeringsloesninger/':
        return <Route key={page.id} path={'/timeinvest/vi-tilbyder/udvildige-investeringsloesninger/'}>
          <InvestQuery page={page}>
            {(data) => <InvestDefault page={data.page} />}
          </InvestQuery>
        </Route>
      case '/timeinvest/vi-tilbyder/formueplan/':
        return <Route key={page.id} path={'/timeinvest/vi-tilbyder/formueplan/'}>
          <InvestQuery page={page}>{(data) => <InvestDefault page={data.page} />}</InvestQuery>
        </Route>
      case '/timeinvest/vi-tilbyder/udvalg-af-fonde/':
        return <Route key={page.id} path={'/timeinvest/vi-tilbyder/udvalg-af-fonde/'}>
          <InvestQuery page={page}>
            {(data) => <><DefaultPage page={data.page}/><InvestmentFunds funds={data.page.pageUdvalgAfFonde.funds} /></>}
          </InvestQuery>
        </Route>
      default:
        return <Route key={page.id} path={path+page.slug}>
          <InvestQuery page={page}>{(data) => <DefaultPage page={data.page}/>}</InvestQuery>
        </Route>
    }
  }

  return (
    <>
      <Switch>
        <Route path={'/timeinvest/blog/:id'} ><NewsPost subsite="TI" /></Route>
        {
          data.pages.nodes.map((page) => renderRoutes(page))
        }
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

TimeinvestRouter.propTypes = {
  data: PropTypes.object.isRequired
}

const InvestPage = () => {
  return (
    <Query query={GET_SUBSITE_PAGES} client={client}>
      {
        ({ loading, error, data }) => {
          if (!data && loading || error) {
            return <AppShell loading={loading} hidenav={true} />
          }

          return <TimeinvestRouter data={data} />
        }
      }
    </Query>
  );

};

export default InvestPage;
