const devMode = process.env.NODE_ENV === 'development';

const FORSIKRING_OG_PENSION = 'F&P'
const TIMELAW = 'JURA'
const REVISION = 'REVISION'
const INVEST = 'INVEST'
const TIMERIGHT = 'TIMERIGHT'

const FORSIKRING_OG_PENSION_URL = devMode ? 'https://timegruppen-stage.maintenance.twentyfour.dev/forsikring-og-pension' : 'https://timegruppen.dk/forsikring-og-pension'
const FORSIKRING_OG_PENSION_TEXT = 'Som en del af Nordens største mæglerhus Söderberg &amp; Partners, tilbyder vi uvildig rådgivning indenfor erhvervsforsikringer og firmapensioner.'
const REVISION_URL = devMode ? 'https://timegruppen-stage.maintenance.twentyfour.dev/timevision' : 'https://timegruppen.dk/timevision'
const REVISION_TEXT = 'Vi planlægger, reviderer og rådgiver indenfor alle væsentlige økonomiske områder, som har indflydelse på jeres virksomheds vækst.'
const INVEST_URL = devMode ? 'https://timegruppen-stage.maintenance.twentyfour.dev/timeinvest' : 'https://timegruppen.dk/timeinvest'
const INVEST_TEXT = 'Vi tilbyder uvildig og strategisk investeringsrådgivning til både organisationer, virksomheder, fonde og private.'

const TIMELAW_URL = devMode ? 'https://timegruppen-stage.maintenance.twentyfour.dev/timelaw' : 'https://timegruppen.dk/timelaw'
const TIMELAW_TEXT = ''

const TIMERIGHT_URL = devMode ? 'https://timegruppen-stage.maintenance.twentyfour.dev/timeright' : 'https://timegruppen.dk/timeright'
const TIMERIGHT_TEXT = ''

const FRONTPAGE_HERO_ITEMS_LIMIT = 6;
const FRONTPAGE_HERO_ITEMS = 4;
const FRONTPAGE_HERO_ID = 'main-hero';

export {
  FORSIKRING_OG_PENSION,
  REVISION,
  INVEST,
  TIMELAW,
  TIMERIGHT,
  FORSIKRING_OG_PENSION_URL,
  FORSIKRING_OG_PENSION_TEXT,
  REVISION_URL,
  REVISION_TEXT,
  INVEST_URL,
  INVEST_TEXT,
  TIMELAW_URL,
  TIMELAW_TEXT,
  TIMERIGHT_URL,
  TIMERIGHT_TEXT,
  FRONTPAGE_HERO_ITEMS_LIMIT,
  FRONTPAGE_HERO_ITEMS,
  FRONTPAGE_HERO_ID
}
