import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const SiteContext = React.createContext({
  site: '',
  currentSiteData: {},
});

export default SiteContext;

export const SiteProvider = (props) => {
  const [site, setSite] = useState('');
  const location = useLocation();


  useLayoutEffect(() => {
    const parts = location.pathname.split('/');
    const secondPartOfUrl = parts && parts.length && parts.length > 1 ? parts[1] : '';
    // const loc = location.pathname.replace(/\/$/, '');

    const timeInvestUrl = 'timeinvest';
    const forsikringsUrl = 'forsikring-og-pension';
    const revisionUrl = 'timevision';
    const timelawUrl = 'timelaw';
    const timerightUrl = 'timeright';

    if (secondPartOfUrl === timeInvestUrl) {
      // console.log('site is timeinvest')
      setSite('TI');
    } else if (secondPartOfUrl === forsikringsUrl) {
      // console.log('site is forsikring')
      setSite('FP');
    } else if (secondPartOfUrl === revisionUrl) {
      // console.log('site is timevision')
      setSite('TV');
    } else if (secondPartOfUrl === timelawUrl) {
      // console.log('site is timelaw')
      setSite('TL');
    } else if (secondPartOfUrl === timerightUrl) {
      setSite('TR');
    }
    else {
      // console.log('site is root TG')
      setSite('TG');
    }
  }, [location, site])

  return (
    <SiteContext.Provider value={{
      site,
      currentSiteData: siteData[site]
    }}>
      {props.children}
    </SiteContext.Provider>
  );
};

const siteData = {
  'TG': {
    baseUrl: '/',
    baseUrlClean: '',
    perPage: 4,
  },
  'TV': {
    baseUrl: '/timevision/',
    baseUrlClean: '/timevision',
    perPage: 4,
  },
  'TI': {
    baseUrl: '/timeinvest/',
    baseUrlClean: '/timeinvest',
    perPage: 8,
  },
  'FP': {
    baseUrl: '/forsikring-og-pension/',
    baseUrlClean: '/forsikring-og-pension',
    perPage: 4,
  },
  'TL': {
    baseUrl: '/timelaw/',
    baseUrlClean: '/timelaw',
    perPage: 4,
  },
  'TR': {
    baseUrl: '/timeright/',
    baseUrlClean: '/timeright',
    perPage: 4,
  },
}

SiteProvider.propTypes = {
  children: PropTypes.any
}
