import React, { useRef, useState, useEffect, useContext} from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { GET_POSTS } from '../../gql';
import {Hero, LoadingIndicator, ReadMore} from '../components';
import ChevronRight from '../../assets/images/ChevronRight.js';
import { getSiteClient } from '../hooks/UseSiteQuery';
import SiteContext from '../context/SiteContext';

const moment = require('moment')
moment.locale('da');


const getReadMoreButton = (post, color = '#f7f7f7') => {
  const link = post.newsPost.external?.externalArticle ? post.newsPost.external.externalLink : post.link
  return <ReadMore external={post.newsPost.external?.externalArticle} label="Læs mere" color={color} link={link} />
}

// The parent page component
const News = ({page, subsite = ''}) => {

  let { url } = useRouteMatch();
  const [posts, setPosts] = useState([]) // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (page && page.page && page.page.seo && page.page.seo.title ) {
      document.title = page.page.seo.title;
    }
    return () => {}
  }, [page])

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPosts(page.posts?.nodes.sort((a, b) => {
      if (b.newsPost.dateLabel) {
        if (a.newsPost.dateLabel) {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.newsPost.dateLabel, 'DD. MMMM YYYY')
        } else {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - a.date
        }
      }
      return b.date - a.date
    }))
  }, [page])

  return (
    <div className="news">
      <Hero
        title={page.page.pageBlocks.hero.header}
        background={page.page.pageBlocks.hero.backgroundImage?.sourceUrl} />
      <Posts baseUrl={url} subsite={subsite}/>
    </div>
  )
}

News.propTypes = {
  page: PropTypes.object.isRequired,
  subsite: PropTypes.string
}

// Component that shows the paginated list of posts
const PostList = ({ data, error, loading, baseUrl}) => { // eslint-disable-line no-unused-vars

  return (
    data ? (
      <div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
          {data.map((post, index) => {
            let excerpt = post.excerpt.replace('&nbsp;', '');
            const link = post.newsPost.external?.externalArticle ? post.newsPost.external.externalLink : post.link
            return (
              <div className="news__post" key={index}>
                <a href={link}>
                  <img srcSet={post.featuredImage?.node.srcSet} alt={post.featuredImage?.node.altText}/>
                </a>
                <div className="content">
                  <div className="date-label">
                    {
                      post.newsPost.dateLabel
                        ? <p>{post.newsPost.dateLabel}</p>
                        : null
                        // : <>{moment(new Date(post.date)).format('Do MMMM YYYY')}</>
                    }
                  </div>
                  <p className="title">{post.title}</p>
                  <div className="mb-4 excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
                </div>
                {getReadMoreButton(post, '#293769')}
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <div>Ingen indlæg fundet.</div>
    )
  )
};
PostList.propTypes = {
  data: PropTypes.any,
  error: PropTypes.any,
  loading: PropTypes.any,
  baseUrl: PropTypes.string,
  currentCategory: PropTypes.string,
}

const Posts = ({baseUrl, subsite}) => {
  const { currentSiteData } = useContext(SiteContext);
  const [posts, setPosts] = useState(undefined)
  const [currentPosts, setCurrentPosts] = useState([]);
  const [loading, setLoading] = useState(true)
  const perPage = currentSiteData.perPage;
  const pageRangeDisplayed = 2;
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [categoryFilterVisible, setCategoryFilterVisible] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [categories, setCategories] = useState([])
  const dropdown = useRef()
  const anchorRef = useRef()

  const getPostsWithSiteClient = async (site) => {
    const client = getSiteClient(site);
    return client.query({ query: GET_POSTS, client: client })
      .then((result) => {
        return result.data.posts.nodes;
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  }

  const handlePageClick = (data) => {
    let { selected } = data
    let offset = Math.ceil(selected * perPage);
    setOffset(offset)
    mapFilteredItems(currentCategory, offset)

    if (anchorRef) {
      anchorRef.current.scrollIntoView()
    }
  };

  const mapFilteredItems = (category, offset = 0) => {
    if (category === null) {
      let filtered = [ ...posts];

      const sorted = [ ...filtered.sort((a, b) => {
        if (b.newsPost.dateLabel) {
          if (a.newsPost.dateLabel) {
            return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.newsPost.dateLabel, 'DD. MMMM YYYY')
          } else {
            return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.date)
          }
        }
        return moment(b.date) - moment(a.date)
      })]

      const newPosts = [ ...sorted.splice(offset, perPage)];

      setCurrentPosts(newPosts)
      setPageCount(Math.ceil([ ...filtered].length / perPage))
      return
    }

    const temp = [ ...posts].filter((node) => {

      if (subsite !== '') {
        return node.categories.nodes.filter((categoryObj) => {
          return categoryObj.name === category
        }).length > 0
      } else {

        switch (category.toLowerCase()) {
          case 'revision & skat' :
          case 'timevision' :
            return node.link.indexOf('timevision') > -1
          case 'investering' :
            return node.link.indexOf('timeinvest') > -1
          case 'forsikring & pension' :
            return node.link.indexOf('forsikring-og-pension') > -1
          case 'jura' :
            return node.link.indexOf('timelaw') > -1
          case 'timeinvest' :
            return node.link.indexOf('timeinvest') > -1
          case 'gdpr' :
            return node.link.indexOf('timeright') > -1
          default: return 'timeinvest'
        }
      }
    })

    const tempSorted = [ ...temp.sort((a, b) => {
      if (b.newsPost.dateLabel) {
        if (a.newsPost.dateLabel) {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.newsPost.dateLabel, 'DD. MMMM YYYY')
        } else {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.date)
        }
      }
      return moment(b.date) - moment(a.date)
    })]

    let filtered = [ ...tempSorted];

    setCurrentPosts([ ...filtered].splice(offset, perPage));
    setPageCount(Math.ceil([ ...tempSorted ].length / perPage))
  }

  const onFilter = (category) => {
    setCurrentCategory(category)
    setCategoryFilterVisible(false)
    let selected = 0;
    let offset = Math.ceil(selected * perPage)
    setOffset(offset)

    mapFilteredItems(category, offset)
  }

  const getAllPosts = async () => {

    const postPromises = []
    if (subsite) {
      postPromises.push(getPostsWithSiteClient(subsite))
    } else {
      postPromises.push(getPostsWithSiteClient('TG'))
      postPromises.push(getPostsWithSiteClient('TI'))
      postPromises.push(getPostsWithSiteClient('TV'))
      postPromises.push(getPostsWithSiteClient('FP'))
      postPromises.push(getPostsWithSiteClient('TL'))
    }

    const all = await Promise.all(postPromises);

    let merged = [].concat.apply([], all);


    const allThePosts = [ ...merged.sort((a, b) => {
      if (b.newsPost.dateLabel) {
        if (a.newsPost.dateLabel) {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.newsPost.dateLabel, 'DD. MMMM YYYY')
        } else {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.date)
        }
      }
      return moment(b.date) - moment(a.date)
    }) ]
    setPosts([ ...allThePosts])


    const sorted = [ ...merged.sort((a, b) => {
      if (b.newsPost.dateLabel) {
        if (a.newsPost.dateLabel) {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.newsPost.dateLabel, 'DD. MMMM YYYY')
        } else {
          return moment(b.newsPost.dateLabel, 'DD. MMMM YYYY') - moment(a.date)
        }
      }
      return moment(b.date) - moment(a.date)
    })];

    const filtered = [ ...sorted.splice(offset, perPage)]


    // Removing 'Uncategorized' from dropdown for subsites.
    // https://trello.com/c/4rDM2yEZ
    const categories = subsite
      ? merged
        .map(item => item.categories.nodes[0].name.trim())
        .filter((x, i, a) => a.indexOf(x) == i && x.toLowerCase() !== 'alle')
        .filter(cat => cat !== 'Uncategorized')
      : ['Revision & Skat', 'Forsikring & Pension', 'Investering', 'Jura', 'GDPR'];

    setCategories([ ...categories]);

    setCurrentPosts([ ...filtered])
    setPageCount(Math.ceil([ ...merged ].length / perPage))
    setLoading(false)
  }

  useEffect(() => { getAllPosts(); }, []);

  if (loading) {
    return (
      <div className="md:mt-10 mt-6">
        {
          !subsite &&
            <>
              <h2 className="md:pt-32 pt-16 md:mb-10 mb-6 text-center">Seneste</h2>
              <div className="news__latest grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mb-32">
                <div className="placeholder-post flex items-center"><LoadingIndicator/></div>
                <div className="placeholder-post flex items-center"><LoadingIndicator/></div>
                <div className="placeholder-post flex items-center"><LoadingIndicator/></div>
              </div>
            </>
        }
        <h2 className="mb-4">Alle nyheder</h2>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
          <div className="placeholder-post flex items-center"><LoadingIndicator/></div>
          <div className="placeholder-post flex items-center"><LoadingIndicator/></div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-x-hidden md:overflow-x-visible">
      {
        !subsite &&
        <>
          <h2 className="md:pt-32 pt-16 md:mb-10 mb-6 text-center">Seneste</h2>
          <div className="news__latest grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mb-32">
            {
              posts.map((post, index) => {
                let excerpt = post.excerpt.replace('&nbsp;', '');


                if (index < 3) {
                  return (
                    <div className="news__post-latest" key={index}>
                      {post.featuredImage &&
                      <img srcSet={post.featuredImage.node.srcSet} alt={post.featuredImage.node.altText}/>}
                      <div className="content">
                        <div className="date-label">
                          {
                            post.newsPost.dateLabel
                              ? <p>{post.newsPost.dateLabel}</p>
                              : null
                              // : <p>{moment(new Date(post.date)).format('Do MMMM YYYY')}</p>
                          }
                        </div>
                        <p className="title">{post.title}</p>
                        <div className="mb-4 excerpt" dangerouslySetInnerHTML={{__html: excerpt}}/>
                      </div>
                      {getReadMoreButton(post)}
                    </div>
                  )
                }
              })
            }
          </div>
        </>
      }
      <div className={`news__filter mb-16 ${subsite ? ' md:pt-32 pt-16' : ''}`} ref={anchorRef}>
        <h2 className="mb-4 md:mb-0">{currentCategory ? currentCategory : 'Alle nyheder'}</h2>
        <div className="dropdown dropdown--filter">
          <div className="dropdown__button" onClick={() => setCategoryFilterVisible(!categoryFilterVisible)}>
            <span>Områder</span>
            <div className="caret" />
          </div>
          <div className={`dropdown__list ${categoryFilterVisible ? 'active' : ''}`} ref={dropdown}>
            <div className="dropdown__item" onClick={() => onFilter(null)}>Alle</div>
            {categories.map((tag, index) => (
              <div className="dropdown__item" key={index} onClick={() => onFilter(tag)}>{tag}</div>
            ))}
          </div>
        </div>
      </div>
      <PostList
        error={'error'}
        loading={loading}
        data={currentPosts}
        baseUrl={baseUrl}
      />
      { !currentPosts.length && !loading ? <h2>Ingen indlæg fundet i kategorien</h2> : null }
      { currentPosts.length && !loading ?
        <div className="flex flex-row justify-center my-28">
          <ReactPaginate
            previousLabel={<span className="pagination__icon previous"><ChevronRight/></span>}
            nextLabel={<span className="pagination__icon next"><ChevronRight /></span>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
        :
        null
      }
    </div>
  );
};

Posts.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  subsite: PropTypes.string
}

export default News
