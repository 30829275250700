import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route} from 'react-router-dom';
import Page from './pages'
import Frontpage from './pages/Frontpage';
import About from './pages/About';
import Contact from './pages/Contact';
import InvestPage from './pages/TimeInvest';
import RevisionPage from './pages/Revision';
import FPPage from './pages/ForsikringPension';
import DefaultPage from './pages/default';
import AboutTeam from './pages/AboutTeam';
import AboutCareer from './pages/AboutCareer';
import NotFound from './pages/404';
import GetStarted from './pages/GetStarted';
import News from './pages/News';
import NewsPost from './pages/NewsPost';
import TimelawHome from './pages/Timelaw';
import TimerightHome from './pages/Timeright';
import { NewsletterForm } from './components';

const Routes = ({pages}) => {
  const renderRoutes = (page) => {
    if (page.uri === '/privacy-policy/' || page.uri === '/privatlivspolitik/') {
      return <Route key={page.id} path={page.uri}><div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} /></Route>
    }

    switch (page.title) {
      case 'Forside':
      case 'Frontpage':
        return <Route exact key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <Frontpage data={data}/>}</Page>} />
      case 'Om os':
      case 'About':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <About data={data}/>}</Page>} />
      case 'CSR':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <DefaultPage page={data.page}/>}</Page>} />
      case 'Vores team':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <AboutTeam data={data}/>}</Page>} />
      case 'Vores identitet':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <DefaultPage page={data.page}/>}</Page>} />
      case 'Karriere':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <AboutCareer data={data}/>}</Page>} />
      case 'Kontakt':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <Contact data={data} area="timegruppen"/>}</Page>} />
      case 'Videnscenter':
        return <Route key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => <News page={data}/>}</Page>} />
      case 'Kom i gang':
        return <Route key={page.id} path={page.uri} render={() => <GetStarted area="timegruppen" />} />
      default:
        return <Route exact key={page.id} path={page.uri} render={() => <Page page={page}>{(data) => {

          if ((data.page && data.page.pageBlocks && data.page.pageBlocks.sections) || (data.page && data.pageBlocks && data.page.pageBlocks.services)) {
            return <>
              <DefaultPage page={data.page}/>
              { data.page.freshmail && data.page.freshmail.showNewsletterForm && <NewsletterForm data={ data.page.freshmail } /> }
            </>
          } else {
            return <>
              <div className="md:mt-14 mt-10" dangerouslySetInnerHTML={{ __html: page.content }} />
              { data.page.freshmail && data.page.freshmail.showNewsletterForm && <NewsletterForm data={ data.page.freshmail } /> }
            </>
          }
        }}</Page>} />
    }
  }

  return (
    <>
      <Switch>
        <Route path={'/timeinvest/'}><InvestPage /></Route>
        <Route path={'/timevision/'}><RevisionPage /></Route>
        <Route path={'/forsikring-og-pension/'}><FPPage /></Route>
        <Route path={'/timelaw/'}><TimelawHome /></Route>
        <Route path={'/timeright/'}><TimerightHome /></Route>
        <Route path={'/videnscenter/:id'} component={NewsPost} />
        <Route path={'/news/:id'}><NewsPost/></Route>
        {pages.map((page) => renderRoutes(page))}
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

Routes.propTypes = {
  pages: PropTypes.array.isRequired,
  menus: PropTypes.object.isRequired
}

export default Routes;
