import React, {useEffect, useState} from 'react';
import {GET_MENUS} from '../../gql';
import {FORSIKRING_OG_PENSION, INVEST, REVISION, TIMELAW, TIMERIGHT} from '../constants';
import {useLocation} from 'react-router-dom';
import {useSiteClient} from '../hooks/UseSiteQuery';
import {Navigation} from './';
import TGlogo from '../../assets/images/TGlogo';

const TopBar = () => {
  const [site, setSite] = useState('');
  const location = useLocation();
  const client = useSiteClient()
  const [data, setQueryObject] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (client && !data) {
      client.query({ query: GET_MENUS, client: client })
        .then((result) => {
          setLoading(false)
          setQueryObject(result.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [client])

  useEffect(() => {
    const parts = location.pathname.split('/');
    const secondPartOfUrl = parts && parts.length && parts.length > 1 ? parts[1] : '';
    // const loc = location.pathname.replace(/\/$/, '');

    const timeInvestUrl = 'timeinvest';
    const forsikringsUrl = 'forsikring-og-pension';
    const revisionUrl = 'timevision';
    const timelawUrl = 'timelaw';
    const timerightUrl = 'timeright';

    if (secondPartOfUrl === timeInvestUrl) {
      // console.log('site is timeinvest')
      setSite(INVEST);
    } else if (secondPartOfUrl === forsikringsUrl) {
      // console.log('site is forsikring')
      setSite(FORSIKRING_OG_PENSION);
    } else if (secondPartOfUrl === revisionUrl) {
      // console.log('site is timevision')
      setSite(REVISION);
    } else if (secondPartOfUrl === timelawUrl) {
      // console.log('site is timelaw')
      setSite(TIMELAW);
    }
    else if (secondPartOfUrl === timerightUrl) {
      // console.log('site is timelaw')
      setSite(TIMERIGHT);
    } else {
      // console.log('site is root TG')
      setSite('TG');
    }
  }, [])

  if (!data || loading) {
    return (
      <div className="placeholder-navigation flex items-center lg:px-36 md:px-20 px-8 justify-between">
        <TGlogo site={site} />
        <div className="placeholder-button hidden lg:block"/>
      </div>)
  }

  return <Navigation data={data.menus} options={data.themeOptions} subsite={site} />
}

export default TopBar
